//INFO: 화면설명
<script>
import LoadingVue from '@/components/ui/Loading.vue'
import { getLabCategory, getLab } from '@/api/labs'
import { getSixTechnology } from '@/api/category/sixTechnologyCategory'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { LoadingVue },
  data() {
    return {
      fieldList: [],
      sixTechnologies: {},
      isLoading: false,
      sixTechByLargeModel: {
        IT: '정보기술',
        BT: '생명공학기술',
        NT: '나노기술',
        ST: '우주항공기술',
        ET: '환경기술',
        CT: '문화기술'
      }
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.fieldList = this.$store.state.labStore.labCategory

    this.setSixTechnologies()
    await this.loadLabCategory()
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    async setSixTechnologies() {
      const [sixTeches, labs] = await Promise.all([getSixTechnology(), getLab()])

      if (!(sixTeches instanceof Array)) return
      if (!(labs.items instanceof Array)) return

      const reducedSixTeches = sixTeches.reduce((acc, cur) => {
        const key = cur.sixTechnologyLarge
        const item = cur.sixTechnologyId
        acc[item] = key
        return acc
      }, {})

      for (const lab of labs.items) {
        const sixTechnologyLarge = reducedSixTeches[lab.sixTechnologyId]

        if (this.sixTechnologies[sixTechnologyLarge]) {
          this.sixTechnologies[sixTechnologyLarge] += 1
        } else {
          this.sixTechnologies[sixTechnologyLarge] = 1
        }
      }
    },

    async loadLabCategory() {
      if (this.fieldList.length !== 0) return

      this.isLoading = true

      const categories = await getLabCategory()
      if (!(categories instanceof Array)) {
        return (this.isLoading = false)
      }

      const fieldList = categories.map((item) => {
        return { id: item.labScientificCategoryId, title: item.labScientificCategoryName, count: 0 }
      })

      const labs = (await getLab()).items
      if (!(labs instanceof Array)) {
        return (this.isLoading = false)
      }

      const countLab = labs.reduce((acc, cur) => {
        const id = cur.labScientificCategoryId
        acc[id] = acc[id] ? acc[id] + 1 : 1
        return acc
      }, {})

      for (const field of fieldList) {
        const count = countLab[field.id]
        if (count) field.count = count
      }

      this.$store.dispatch('labStore/SetLabCategory', fieldList)

      this.fieldList = fieldList
      this.isLoading = false
    },

    onClickHandler(key) {
      this.$router.push(`/researchField/result?q=${`sixTechnologyLarge=${key}`}`)
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <article class="wf__fields">
    <div class="wf__field-item" v-for="(value, key) in sixTechByLargeModel" :key="key" @click="onClickHandler(key)">
      <h4 class="wf__filed-item-title" v-text="value"></h4>

      <p class="wf__filed-item-desc">
        <span class="wf__filed-item-desc-count">{{ sixTechnologies[key] || 0 }}</span>
        {{ LanguageConverter({ en: '&nbsp;labs', kr: '개 연구실' }) }}
      </p>
    </div>
  </article>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
.wf__fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;

  margin: 3em 0;
}

.wf__field-item {
  border-radius: 0.4em;
  background-color: var(--wf-misty-color);
  padding: 1em;
  text-align: center;

  cursor: pointer;

  transition: all 0.3s ease-out;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px; */
}

.wf__field-item:hover {
  background-color: #eee;
}
.wf__filed-item-title {
  font-weight: bold;
  font-size: var(--wf-text-24);
  margin-bottom: 0.25em;
}
.wf__filed-item-desc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wf__filed-item-desc-count {
  color: var(--wf-primary-color);
  font-size: var(--wf-text-20);
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .wf__fields {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__fields {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
